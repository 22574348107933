
import { defineComponent } from "vue";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
export default defineComponent({
  name: "swiper",
  components: {
    Viewer,
  },
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/生涯云平台/1.jpg"),
      ],

      img2: require("@/assets/1new/生涯云平台/2.jpg"),
      img3: require("@/assets/1new/生涯云平台/3.jpg"),
      platfromDetail: [
        require("@/assets/1new/生涯云平台/轮播图片/1.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/2.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/3.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/4.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/5.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/6.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/7.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/8.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/9.jpg"),
      ],
    };
  },
  methods: {
    goXSY() {
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    },
  },
  setup(){
    
  }
});
