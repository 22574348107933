<template>
  <div class="platform">
    <div class="swiper">
      <el-carousel height="6.40rem">
        <el-carousel-item v-for="item in imgList" :key="item">
          <img @click="goXSY()" class="img" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="centerTitle">
        生涯资讯
      </div> -->

      <!--  -->
    </div>
    <div class="introduce">
      <p class="mess">
        生涯云平台技术架构成熟稳定，数据处理流畅，操作维护简洁高效，已通过国家知识产权体系认证和国家版权局认证。教育局、校长、教师、学生端口数据互通，同时支持小程序APP使用。生涯云平台是学校、教师、学生日常学习、升学管理的云数据专家。
      </p>
    </div>
    <img
      class="img2"
      :src="img2"
      alt=""
      ondragstart="return false;"
      oncontextmenu="return false;"
    />
    <div class="platformDetail">
      <img
        class="img3"
        :src="img3"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <el-carousel :interval="4000" type="card" height="2.53rem">
        <el-carousel-item v-for="item in platfromDetail" :key="item">
          <!-- <h3 class="medium">{{ item }}</h3> -->
          <img class="platfromDetailImg" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
export default defineComponent({
  name: "swiper",
  components: {
    Viewer,
  },
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/生涯云平台/1.jpg"),
      ],

      img2: require("@/assets/1new/生涯云平台/2.jpg"),
      img3: require("@/assets/1new/生涯云平台/3.jpg"),
      platfromDetail: [
        require("@/assets/1new/生涯云平台/轮播图片/1.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/2.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/3.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/4.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/5.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/6.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/7.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/8.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/9.jpg"),
      ],
    };
  },
  methods: {
    goXSY() {
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    },
  },
  setup(){
    
  }
});
</script>
<style lang='scss' >
.platform {
  background-color: #fff;
  height: 100%;
  .swiper {
    width: 1920px;
    height: 1280px;
    margin-left: -360px;
    position: relative;
    // cursor: pointer;
    .img {
      width: 1920px;
      height: 1280px;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 497px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
//
.introduce {
  margin: 0 auto;
  background-color: #fff;
  width: 1200px;
  color: #333;

  font-weight: bold;
  padding: 40px 0;
  .mess {
    width: 800px;
    margin: 0 auto;
    text-indent: 2em;
    font-size: 16px;
    line-height: 26px;
  }
}
// 平台介绍
.img2 {
  width: 1200px;
  height: 100%;
}
.img3 {
  width: 1200px;
  height: 140px;
}
.platformDetail {
  width: 1200px;
  height: 670px;
  .platfromDetailImg {
    width: 626px;
    height: 506px;
  }
}
</style>
